import { useHistory } from "react-router-dom";
import "./DeferredStudentCard.css";

function DeferredStudentCard({ deferredStudentDetails }) {
  const history = useHistory();

  const navigateToGoals = () => {
    history.push(
      `/deferred-program/goals?bootcampid=${deferredStudentDetails?.bootcampid}`
    );
  };

  return (
    <div className={`dash-container deferred-student-container text-black`}>
      {deferredStudentDetails?.showDeferredStudentCard && (
        <div className="d-flex flex-column m-3 rounded py-2 px-4 border bg-white">
          <p className="m-0 text-lg my-2">You have been enrolled in the</p>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="m-0 text-2xl">Zaio Deferred Program</p>
            <button className="text-2xl text-underline">Handbook</button>
          </div>
          {deferredStudentDetails?.yellowCardCount === 0 ? (
            <p className="text-center m-0 my-4">
              You have not failed any goals yet ! :)
            </p>
          ) : (
            <div>
              <div className="deferred-student-yellow-card-container">
                <div className="card-container my-4">
                  <div className="deferred-student-cards">
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        className={`deferred-student-card rounded mx-2 ${
                          index < deferredStudentDetails?.yellowCardCount
                            ? "yellow-card"
                            : "empty-card"
                        }`}
                      ></div>
                    ))}
                  </div>
                  <p className="m-0 mt-2 text-center text-md font-bold">
                    You have got {deferredStudentDetails?.yellowCardCount}{" "}
                    Yellow cards
                  </p>
                </div>
              </div>
            </div>
          )}

          <button
            onClick={navigateToGoals}
            className="text-2xl btn-main btn bg-none border w-50 text-white mx-auto mb-3 shadow-sm"
          >
            View Goals
          </button>
        </div>
      )}
      {deferredStudentDetails?.showTutorCard && (
        <div className="row m-3 rounded py-3 px-4 border bg-white">
          <div className="col-4">
            <img
              src={deferredStudentDetails?.tutorDetails?.image}
              className="inline-block size-28 rounded-full"
              style={{
                objectFit: "fill",
              }}
            />
          </div>
          <div className="col-8 d-flex flex-column justify-content-between align-items-center mt-2">
            <p className="m-0 text-2xl">
              {deferredStudentDetails?.tutorDetails?.company_username}
            </p>
            <button
              onClick={() => {
                window.open(
                  deferredStudentDetails?.tutorDetails?.calendlyLink,
                  "_blank"
                );
              }}
              className="text-2xl btn btn-main bg-none border text-white w-75 mx-auto mb-3 shadow-sm"
            >
              Book my Tutor
            </button>
          </div>
        </div>
      )}
      {deferredStudentDetails?.showSSMCard && (
        <div className="row m-3 rounded py-3 px-4 border bg-white">
          <div className="col-4">
            <img
              src={deferredStudentDetails?.ssmDetails?.image}
              style={{
                width: 125,
                height: 125,
                borderRadius: 999,
              }}
            />
          </div>
          <div className="col-8 d-flex flex-column justify-content-between align-items-center mt-2">
            <p className="m-0 text-2xl">
              {deferredStudentDetails?.ssmDetails?.company_username}
            </p>
            <button
              onClick={() => {
                window.open(
                  deferredStudentDetails?.ssmDetails?.calendlyLink,
                  "_blank"
                );
              }}
              className="text-2xl btn btn-main bg-none border text-white w-75 mx-auto mb-3 shadow-sm"
            >
              Book Student Success Manager
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeferredStudentCard;
