import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueryClient, useQuery } from "react-query";

import classes from "./Dashboardnew.module.css";

import { UserContext } from "context/UserProvider";
import DashboardNavbar from "component/navbar/Navbar";

import TeamCard from "./TeamCard";
import authService from "actions/services/auth.service";
import Realistic from "components/Confetti/Confetti";
import ContinueLearningCard from "./ContinueLearning/ContinueLearningCard";
import LearningPaths from "./MyLearningPaths/LearningPaths";

import learningpathService from "actions/services/learningpath.service";
import "./Dashboard.scss";
import UpgradeCard from "./Upgrade/UpgradeCard";
import { Mixpanel } from "Utils/Mixpanel";
import Alert from "react-bootstrap/Alert";

import ChatBubble from "./ChatBubble";
import CustomModal from "components/Modals/CustomModal";
import { BootcampModal } from "component/TasksCalendar/BootcampModal";
import bootcamp from "actions/services/bootcamp.service";
import UpgradeCardNew from "./Upgrade/UpgradeCardNew";
import DeferredStudentCard from "./StudentDeferredProgram/DeferredStudentCard";

const Dashboardnew = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [modal, setModal] = useState({
    show: false,
    CustomComponent: null,
    props: null,
    title: "Bootcamp mode",
    description:
      "This is your personalized schedule, with reminders to help you stay upto date with your learning, depending on how much time you can commit",
  });
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(true);
  const [deferredStudentDetails, setDeferredStudentDetails] = useState(null);

  //queries
  const {
    isLoading,
    isError,
    error,
    data: continueLearning,
  } = useQuery(
    "continueLearning",
    () => learningpathService.getContinueLearning(),
    {
      enabled: !!(user && user.success),
    }
  );

  useEffect(() => {
    Mixpanel.track("Web Page view", {
      "Page url": "/updated-dashboard",
      "Page name": "Dashboard Page",
      $distinct_id: user?.data?.email,
    });
    window.webengage?.user.login(user?.data?.email || "");
    const querySkip = Boolean(queryParams.get("skip"));
    if (querySkip) {
      setIsOnboardingComplete(true);
      return;
    }
    if (user && user.success) {
      authService.updateUserData();
    }
    if (
      user?.data?.onboardingProgress &&
      user?.data?.onboardingProgress?.paymentDetailsStep === false
    ) {
      history.replace("/onboarding/payments");
      return;
    }
    if (
      user?.data?.onboardingProgress &&
      user?.data?.onboardingProgress?.questionsStep === false
    ) {
      history.replace("/onboarding/questions");
      return;
    }
    if (
      user?.data?.onboardingquestions &&
      user?.data?.onboardingquestions.length !== 0
    ) {
      console.log(
        "USER cl",
        user.data.onboardingquestions[0].reelOnboardingComplete == ""
      );
      setIsOnboardingComplete(true);
      // setIsOnboardingComplete(
      //   user.data.onboardingquestions[0].reelOnboardingComplete == ""
      //     ? false
      //     : true
      // );
    }
  }, []);

  const handleOnHide = () => {
    setModal({
      ...modal,
      show: false,
      CustomComponent: null,
      props: null,
    });
  };

  const loadModal = async () => {
    const learningpath = "63316ed3d04d33fc63950e3f";
    const enrolledData = await bootcamp.checkAlreadyEnrolled({
      email: user?.email,
      learningpath,
    });

    if (enrolledData?.enrolled) {
      history.push(`/bootcamp-mode?learningpath=${learningpath}`);
    } else {
      setModal({
        ...modal,
        show: true,
        CustomComponent: BootcampModal,
        props: {
          user,
          learningpath,
        },
      });
    }
  };

  useEffect(() => {
    bootcamp.getDashboardCardDetails().then((res) => {
      if (res?.status === 200) {
        setDeferredStudentDetails(res);
      }
    });
  }, [history]);

  // console.log("USER FROM DASH", user);
  return (
    <>
      <Realistic />
      <div className="dashboard-container">
        <DashboardNavbar />
        <div className="title-container">
          <div className="title">
            <h1>Welcome {user?.username} 👋🏻</h1>
          </div>
        </div>

        {/* {(user?.pendingassignments || user?.data?.pendingassignments) && (
          <ChatBubble onClick={() => history.push("/assignments")} />
        )} */}

        {continueLearning && (
          <div className="cardBlock">
            <ContinueLearningCard
              isOnboardingComplete={isOnboardingComplete}
              continueLearning={continueLearning}
            />
            {/* <TeamCard /> */}
            {/* <UpgradeCardNew
              isOnboardingComplete={isOnboardingComplete}
              continueLearning={continueLearning}
              fromDashboard
            /> */}
            {(deferredStudentDetails?.showDeferredStudentCard ||
              deferredStudentDetails?.showTutorCard ||
              deferredStudentDetails?.showSSMCard) && (
              <DeferredStudentCard
                deferredStudentDetails={deferredStudentDetails}
              />
            )}
            {/* <ContinueLearningCard /> */}
          </div>
        )}
        {modal.show && (
          <CustomModal
            show={modal.show}
            title={modal.title}
            description={modal.description}
            handleOnHide={handleOnHide}
            props={modal.props}
            CustomComponent={modal.CustomComponent}
          />
        )}
        {/* <div>
          <button
            onClick={loadModal}
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Bootcamp Mode
          </button>
        </div> */}

        <LearningPaths />
      </div>
    </>
  );
};
export default Dashboardnew;

// modals
{
  /* <Modal
        show={viewAddons}
        size="md"
        onHide={() => setViewAddons(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-5" style={{ paddingTop: "0px" }}>
          <h2 className="mediumText text-primary mb-3">
            Enroll into a learning path to get access to this offering
          </h2>
          <Form>
            <Form.Group className="">
              This includes accessing mentorship from successful developers
              accross the world, a guaranteed internship placement that could
              lead to a full-time role, NQF level 5 certification, and access to
              the jobs list.
            </Form.Group>
          </Form>
          <button
            className=" mt-4 btn btn-block btn-main"
            onClick={() => history.push("/choose-path")}
          >
            Choose a learning path
          </button>
        </Modal.Body>
      </Modal> */
}

// STREAKS AND TEAM STREAKS

// <div className={classes.cards}>
//             {/* <p className={classes.cardHeading}>Streaks &amp; Team Streaks</p> */}
//             <div className={classes.cardBody} style={{ paddingTop: 17 }}>
//               <div className={classes.paidCardTop}>
//                 <span className={classes.paidCardHeading}>
//                   A video a day earns a streak a day. Miss a day, lose the
//                   streak.
//                 </span>
//                 <div
//                   className={classes.perksWrapper}
//                   style={{ position: "static" }}
//                 >
//                   <img
//                     alt="img1"
//                     src={
//                       "https://zaiolandingpage.s3.eu-west-2.amazonaws.com/medal.png"
//                     }
//                     className={`${classes.icon} ${classes.tutor1}`}
//                   />
//                   {/* <img
//                       alt="img1"
//                       src={"https://zaiolandingpage.s3.eu-west-2.amazonaws.com/money.png"}
//                       className={`${classes.icon} ${classes.tutor2}`}

//                     /> */}
//                   <img
//                     alt="img1"
//                     src={
//                       "https://zaiolandingpage.s3.eu-west-2.amazonaws.com/stats.png"
//                     }
//                     className={`${classes.icon} ${classes.tutor3}`}
//                     // style={{ position: "absolute", left: 70, top: 0 }}
//                   />
//                   <img
//                     alt="img1"
//                     src={
//                       "https://zaiolandingpage.s3.eu-west-2.amazonaws.com/quiz.png"
//                     }
//                     className={`${classes.icon} ${classes.tutor4}`}
//                     // style={{ position: "absolute", left: 105, top: 0 }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className={classes.paidCardProgressBar}
//                 style={{ display: "flex", justifyContent: "center" }}
//               >
//                 <Stats streaksOnly teamStreaks />
//                 <Stats streaksOnly />

//                 {/* <ProgressBar
//                     now={45}
//                     className={classes.courseProgressBar}
//                     style={{ width: "100%" }}
//                   /> */}
//               </div>
//               <div className={classes.cardText}>
//                 <span
//                   className={classes.left}
//                   onClick={() =>
//                     alert(
//                       "Streaks measure your consistency. If you are consistent in your learning, your chances of succeeding in industry are higher. So be consistent, and earn rewards for it. Rewards such as free courses, free consultations, free mentorship, and real goodies, vouchers and more."
//                     )
//                   }
//                 >
//                   <i>What is this?</i>
//                 </span>

//                 <span className={classes.right + " " + classes.proIcon}>
//                   <a
//                     href={`https://wa.me/+27763793540/?text=Hi there, My name is ${user.username} and I wanted to connect from my dashboard to get some advice. My email is ${user.email}`}
//                     target="_blank"
//                     // className="text-white"
//                   >
//                     Reach a tutor
//                   </a>
//                 </span>
//               </div>
//             </div>
//           </div>

// // SECURE YOUR CAREER
// <div className={classes.cards}>
//               <p className={classes.cardHeading}>Secure your career</p>
//               <div className={classes.cardBody}>
//                 <div className={classes.allIcons}>
//                   <div>
//                     <div className={classes.icons}>
//                       <img
//                         alt="img2"
//                         src={Lock}
//                         className={classes.iconCenter}
//                       />
//                     </div>
//                     <p>Mentorship access</p>
//                   </div>
//                   <div>
//                     <div className={classes.icons}>
//                       <img
//                         alt="img2"
//                         src={Lock}
//                         className={classes.iconCenter}
//                       />
//                     </div>
//                     <p>NFQ level 5 certification</p>
//                   </div>
//                   <div>
//                     <div className={classes.icons}>
//                       <img
//                         alt="img2"
//                         src={Lock}
//                         className={classes.iconCenter}
//                       />
//                     </div>
//                     <p>Internship offer</p>
//                   </div>
//                   <div>
//                     <div className={classes.icons}>
//                       <img
//                         alt="img2"
//                         src={Lock}
//                         className={classes.iconCenter}
//                         style={{ width: 14 }}
//                       />
//                     </div>
//                     <p>Job board listing</p>
//                   </div>
//                   <div>
//                     <div className={classes.icons}>
//                       <img
//                         alt="img2"
//                         src={minutIcon}
//                         className={`${classes.iconCenter}`}
//                         style={{ width: 14 }}
//                       />
//                     </div>
//                     <p>{minutesRemaining} free minutes</p>
//                   </div>
//                 </div>
//                 <div className={`${classes.cardText}`}>
//                   <span className={classes.left}>
//                     <a
//                       href={`https://wa.me/+27763793540/?text=Hi there, My name is ${user.username} and I wanted to connect from my dashboard to get some advice. My email is ${user.email}`}
//                       target="_blank"
//                       className="text-white"
//                     >
//                       Access Free mins
//                     </a>
//                   </span>
//                   <span
//                     className={classes.right}
//                     onClick={() => setViewAddons(true)}
//                   >
//                     View all
//                   </span>
//                 </div>
//               </div>
//             </div>
